<template>
<!--  角色管理-->
    <div class="roleManagement">
      <a-spin :indicator="indicator" :spinning="spinning">
      <div class="select_Info">
        <div>
          名称:  &nbsp;<a-input v-model="searchName" placeholder="输入名称搜索" style="width: 210px" size="default" />
          <a-button style="margin-left: 10px" type="primary" @click="searchOk">查询</a-button>
          <a-button style="margin-left: 10px"  @click="searchBtn">重置</a-button>
        </div>
      </div>

       <div class="table_Info">
         <a-button type="primary" @click="()=>this.addShow = true"> 新增</a-button>
         <a-table :columns="columns" style="min-height: 500px"  :data-source="InfoTable" :pagination="false" rowKey="id">
           <span slot="check" slot-scope="item">
<!--             <a  @click="checkOK(item.id)"> 查看成员</a>-->
             <a  > 查看成员</a>
           </span>
           <span slot="operate" slot-scope="item">
             <a  @click="operateBtn(item.id,item.mid)"> 设置</a>
           </span>
           <span slot="edit" slot-scope="item">
             <a style="margin-right: 10px" @click="editBtn(item.id,item)"> 编辑</a>
             <a-popconfirm
                 title="确定删除?"
                 ok-text="是"
                 cancel-text="否"
                 @confirm="delRules(item.id)"
                 @cancel="cancel"
             >
            <a >删除</a>
            </a-popconfirm>
           </span>
         </a-table>
         <div  style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;">
           <a-pagination v-model="pagination.current"
                         :total="pagination.total"
                         @change="tableChange" />
         </div>
       </div>



        <a-modal v-model="addShow" title="新增" @ok="addOk" >
          <a-form-model :model="addForm" :label-col="labelCol" :rules="rules" :wrapper-col="wrapperCol" ref="add">
            <a-form-model-item prop="name" label="名称">
              <a-input v-model="addForm.name" />
            </a-form-model-item>

            <a-form-model-item prop="remark" label="备注">
              <a-input v-model="addForm.remark" type="textarea" />
            </a-form-model-item>

          </a-form-model>
        </a-modal>
        <a-modal v-model="editShow" title="修改" @ok="editOk" >
          <a-form-model :model="editForm" :label-col="labelCol" :rules="rules" :wrapper-col="wrapperCol" ref="edit">
            <a-form-model-item prop="name" label="名称">
              <a-input v-model="editForm.name" />
            </a-form-model-item>

            <a-form-model-item prop="remark" label="备注">
              <a-input v-model="editForm.remark" type="textarea" />
            </a-form-model-item>

          </a-form-model>
        </a-modal>
        <a-drawer
            title="成员"
            width="500"
            :visible="checkShow"
            @close="()=>{this.checkShow = false}"
        >
          <a-table :columns="columnM" :showHeader="false" :data-source="memberList" :pagination="false" :rowKey="(record,index)=>{return index}">

          </a-table>
        </a-drawer>
        <a-drawer
            title="设置"
            width="700"
            :visible="operateShow"
            @close="operateClose"
        >
          <div style="overflow-y:auto;min-height: 600px;margin-bottom: 50px">


          <div> <input type="checkbox" id="allBtn"  style="vertical-align:-3px;height: 15px;width: 15px;"
                      @click="allBtn"/> &nbsp;<span style="font-weight: bolder;font-size: 16px">全选</span>
          </div>
          <div v-for="(item,index) in menus" :key="item.id">
            <div style="margin-top: 10px">
<!--              一级菜单复选框-->
            <input :id="'id'+index"  type="checkbox" name="menuCheck" v-model="menuID" style="vertical-align:-3px;height: 15px;width: 15px;"
                   :value="item.id"   @click="allOne(item.zmenu,index)"/>


                &nbsp;<span style="font-weight: bolder;font-size: 16px">{{ item.name }}</span>
            </div>
            <div  style="display: flex;flex-wrap: wrap" >
             <div  v-for="list in item.zmenu" :key="list.id" style="width: 25%;margin-top: 10px">
<!--                二级菜单复选框-->

              <input :id="'id'+list.id" type="checkbox" name="menuCheck"  @click="allTwo(item.id,index)" style="vertical-align:-3px;height: 15px;width: 15px;"
                       v-model="menuID" :value="list.id"/>
                &nbsp;{{list.name}}


              </div>
            </div>
          </div>
          </div>
          <div
              :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
          >
            <a-button style="marginRight: 8px" @click="()=>{this.operateShow = false}">
              取消
            </a-button>
            <a-button type="primary" @click="operateOk">
              确认
            </a-button>
          </div>
        </a-drawer>
      </a-spin>
    </div>

</template>

<script>


import {
  delRule,
  getRuleMenu,
  getRules,
  getRulesMember,
  postRule,
  putRule,
  postRuleMenu
} from "@/service/menuManagement_api";

export default {
  name: "roleManagement",
  data(){
    return {
      spinning: false,
      addShow:false,
      editShow:false,
      checkShow:false,
      operateShow:false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      searchName:'',
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      InfoTable:[],
      memberList:[],
      menus:[
        {
          mids:[]
        }
      ],
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }],
        remark:[
          {required: true, message: '请输入备注', trigger: 'blur' }
        ]
      },
      editForm:{},
      addForm:{},
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      columnM:[
        {
          title:'人员',
          dataIndex:'name'
        }
      ],
      columns:[
        {
          title: '名称',
          dataIndex: 'name',
        },
        {
          title: '备注',
          dataIndex: 'remark',
        },
        {
          title: '成员',
          scopedSlots: { customRender: 'check' },
        },
        {
          title: '菜单权限',
          scopedSlots: { customRender: 'operate' },
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'edit' },
        },
      ],
      editId:'',
      menuID:[],
      ruleMenuID:[],
      allBtnId:''
    }
  },
   created() {
    this.$store.dispatch('setManageHeaderTitle',"角色管理")
  },
  mounted() {
    this.getRuleList()

  },
  methods:{
    //获取列表
    async getRuleList(pageNum,name){
      this.spinning =true
      const Info =await getRules(pageNum,name)
      if(Info.code === 0){
        this.InfoTable = Info.data.rows
        this.pagination.total = Info.data.count
      }
      this.spinning =false
    },
    searchOk(){
      this.getRuleList('1',this.searchName)
      this.pagination.current = 1
    },
    searchBtn(){
      this.getRuleList()
      this.searchName=''
    },
    //分页
    tableChange(e){
      this.getRuleList(e)
    },
    //修改按钮
    editBtn(id,ref){
      this.editShow =true
      this.editId = id
      this.editForm = JSON.parse(JSON.stringify(ref))
    },
    //确认修改
    async editOk(){
      this.$refs.edit.validate(async valid =>{
        if (valid){
          const Upload = await putRule(this.editId,this.editForm)
          if (Upload.code===0){
            //刷新列表
            await this.getRuleList(this.pagination.current)
            this.$message.success("修改成功")
            //清空输入框
            this.$refs.edit.resetFields();
            //关闭对话框
            this.editShow= false;
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //新增确认
    async addOk(){
      this.$refs.add.validate(async valid =>{
        if (valid){
          const Upload = await postRule(this.addForm)
          if (Upload.code===0){
            //刷新列表
            await this.getRuleList(this.pagination.current)
            this.$message.success("添加成功")
            //清空输入框
            this.$refs.add.resetFields();
            //关闭对话框
            this.addShow= false;
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    // //查看人员
    // async checkOK(id){
    //   this.checkShow = true
    //   const Info = await getRulesMember(id)
    //   if (Info.code === 0){
    //     this.memberList = Info.data
    //   }
    // },
    //菜单权限设置
    async operateBtn(id,mid){
      this.ruleId =id
      const Info = await getRuleMenu()
      this.menus =Info.data.menu
      let arr = []
      mid.forEach(item => {
        arr.push(item.mid)
      })
      this.menuID =arr
      this.operateShow = true
    },
    operateClose(){
      this.operateShow = false
      this.menuID = null
    },
    //全选菜单id
    allBtn(){
      let all = document.getElementById("allBtn");
      let mychk = document.getElementsByName("menuCheck");
      if(all.checked===true){
        if(mychk.length){
          for(let i=0;i<mychk.length;i++){
            mychk[i].checked = true;
          }
        }
        mychk.chcked=true;
      }else{
        if(mychk.length){
          for(let j=0;j<mychk.length;j++){
            mychk[j].checked = false;
          }
        }}
    },
    //一级菜单全选
    allOne(row,index){
      let all = document.getElementById("id"+index);
      let temp = row.map(item => {
        return item.id;
      });
      if(all.checked===true){
        for (let j = 0;j<temp.length;j++){
          let sonId = document.getElementById("id"+temp[j]);
          sonId.checked=true
        }
      }else {
        for (let j = 0;j<temp.length;j++){
          let sonId = document.getElementById("id"+temp[j]);
          sonId.checked=false
        }
      }
    },
    allTwo(oneId,index){
      let all = document.getElementById("id"+index);
      all.checked=true

    },
    //菜单权限设置确认
    async operateOk(){
      let obj = document.getElementsByName('menuCheck');
      let s = '';
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].checked) s += obj[i].value + ',';
        this.allBtnId = s.substring(0, s.lastIndexOf(','))
      }
        const data = {
         mid : this.allBtnId
       }
       const Info = await postRuleMenu(this.ruleId,data)
       if (Info.code === 0){
         this.$message.success('设置成功')
         await this.getRuleList(this.pagination.current)
         this.menuID = null
         this.operateShow = false
       }
     },
    //删除角色
    async delRules(id){
      const del = await delRule(id)
      if (del.code === 0 ){
        this.$message.success('删除成功')
        await this.getRuleList(this.pagination.current)
      }
      else {
        this.$message.error('删除失败')
      }
    },
    cancel(){
      this.$message.warning('取消删除');
    },
  },


}
</script>

<style lang="scss" scoped>
::v-deep .ant-checkbox-wrapper + .ant-checkbox-wrapper{
  margin-left: 0;
}
.roleManagement{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .select_Info{
    border-radius: 3px;
    background-color: white;
    height: 80px;
    width: 98%;
    margin: 0 auto;
    line-height: 80px;
    display: flex;
    padding: 0 15px 0 15px;
  }
  .table_Info{
    height:100%;
    width: 98%;
    margin: 10px auto;
    padding: 15px;
    min-height: 400px;
    background-color: white;

  }
}
</style>
